<template>
  <div class="container">
    <div class="free-content">
      <div class="free-content__icon">
        <svg width="128" height="183" viewBox="0 0 128 183" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M75.1995 115.01H89.9396C92.1746 115.01 93.9307 113.162 93.9307 110.934C93.9307 108.651 92.1746 106.858 89.9396 106.858H75.1995C72.9645 106.858 71.2085 108.651 71.2085 110.934C71.2085 113.162 72.9645 115.01 75.1995 115.01ZM107.45 86.7548C110.696 86.7548 112.825 87.8961 114.953 90.3961C117.082 92.896 117.454 96.483 116.975 99.7384L111.92 135.39C110.962 142.243 105.215 147.292 98.457 147.292H40.401C33.3236 147.292 27.4701 141.754 26.8847 134.58L21.9891 75.3364L13.9538 73.9234C11.8253 73.543 10.3353 71.4234 10.7078 69.2496C11.0803 67.0268 13.1556 65.5539 15.3374 65.8855L28.0288 67.8365C29.8381 68.168 31.1684 69.6843 31.3281 71.5321L32.3391 83.7059C32.4987 85.4505 33.8823 86.7548 35.5851 86.7548H107.45ZM39.5512 155.882C35.0812 155.882 31.4627 159.577 31.4627 164.143C31.4627 168.653 35.0812 172.349 39.5512 172.349C43.9679 172.349 47.5864 168.653 47.5864 164.143C47.5864 159.577 43.9679 155.882 39.5512 155.882ZM99.4122 155.882C94.9423 155.882 91.3237 159.577 91.3237 164.143C91.3237 168.653 94.9423 172.349 99.4122 172.349C103.829 172.349 107.447 168.653 107.447 164.143C107.447 159.577 103.829 155.882 99.4122 155.882Z"
                fill="#E0E0E0"></path>
          <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M71 60.5003C55.82 60.5003 43.5 48.2078 43.5 33.0003C43.5 17.8203 55.82 5.50029 71 5.50029C86.2075 5.50029 98.5 17.8203 98.5 33.0003C98.5 48.2078 86.2075 60.5003 71 60.5003ZM79.7732 43.2028C80.1032 43.3953 80.4607 43.5053 80.8457 43.5053C81.5332 43.5053 82.2207 43.1478 82.6057 42.4878C83.1832 41.5253 82.8807 40.2603 81.8907 39.6553L72.1007 33.8253V21.1203C72.1007 19.9653 71.1657 19.0578 70.0382 19.0578C68.9107 19.0578 67.9757 19.9653 67.9757 21.1203V35.0078C67.9757 35.7228 68.3607 36.3828 68.9932 36.7678L79.7732 43.2028Z"
                fill="#ABD20C"></path>
        </svg>
      </div>
      <div class="free-content__title title">{{ message }}</div>
      <div class="free-content__subtitle title title--second"></div>
      <div class="free-content__btn">
        <button @click.prevent="$router.push(`/personal/orders/${orderId}`)"
                class="btn">Перейти к заказу</button>
        <template v-if="!detail.isPaid">
          <template v-for="(item, index) in detail.payments.paySystem" :key="index">
            <div v-if="item.form" v-html="item.form"></div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
      function readCookie(name) {
        if (document.cookie.length > 0) {
          var offset = window.offset
          offset = document.cookie.indexOf(name + '=')
          if (offset != -1) {
            offset = offset + name.length + 1
            var tail = window.tail
            tail = document.cookie.indexOf(';', offset)
            if (tail == -1) tail = document.cookie.length
            return unescape(document.cookie.substring(offset, tail))
          }
        }
        return null
      }

      var lh_clid = '64d3a0c073efc32c727c2ba8'/* ID Магазина */

      /* Вместо строки в кавычках подставить конкретное значение */
      // Код заказа
      var order_id = this.detail.id //'ORDER_ID_HERE' // String
      // Сумма заказа
      var cart_sum = this.detail.payments.price + '.00' // 'CART_SUM_HERE' // String
      /*
        ВАЖНО:
        Сумма заказа имеет строковый тип.
        Формат должен быть 200.00 - разделитель точка и 2 знака после точки.
        В случае несоблюдения этого правила, суммы заказа будут сохраняться некорректно,
        что приведет к ошибкам в данных.
      */
      var order_offers = [] /* товары в заказе */
      order_offers = this.detail.products
      // var order_offers = [
      //   {
      //     'url': 'https://medieval-shop.io/offers/broken_sword',
      //     'name': 'Сломанный меч',
      //     'price': 500,
      //     'count': 1,
      //     'currency' : 'RUB'
      //   },
      //   {
      //     'url': 'https://medieval-shop.io/offers/excellent_helmet',
      //     'name': 'Превосходный шлем',
      //     'price': 2000,
      //     'count': 3,
      //     'currency' : 'USD'
      //   }
      // ]

      /* Товары представляют из себя массив объектов. Каждый объект товара содержит следующие атрибуты:
      Обязательные поля:
      - url: Url товара. Обязательно должен совпадать с урлом этого же товара в YML файле (string)

      Необязательные поля:
      - name: Название товара (string)
      - price: Стоимость товара (int или float)
      - count: Количество купленных экземпляров товара (int)
      - currency: Код валюты в формате ISO 4217 - https://ru.wikipedia.org/wiki/ISO_4217 (string)

      Пример:
      var order_offers = [
          {
              'url': 'https://medieval-shop.io/offers/broken_sword',
              'name': 'Сломанный меч',
              'price': 500,
              'count': 1,
              'currency' : 'RUB'
          },
          {
              'url': 'https://medieval-shop.io/offers/excellent_helmet',
              'name': 'Превосходный шлем',
              'price': 2000,
              'count': 3,
              'currency' : 'USD'
          }
      ] */

      var uid = readCookie('_lhtm_u')
      var vid = readCookie('_lhtm_r').split('|')[1]
      var url = encodeURIComponent(window.location.href)
      var path = 'https://track.leadhit.io/stat/lead_form?f_orderid=' + order_id + '&url=' + url + '&action=lh_orderid&uid=' + uid + '&vid=' + vid + '&ref=direct&f_cart_sum=' + cart_sum + '&clid=' + lh_clid

      var sc = document.createElement('script')
      sc.type = 'text/javascript'
      var headID = document.getElementsByTagName('head')[0]
      sc.src = path
      headID.appendChild(sc)

      if (Array.isArray(order_offers) && order_offers.length > 0) {
        var requestBody = {
          'order_id': order_id,
          'cart_sum': cart_sum,
          'vid': vid,
          'uid': uid,
          'clid': lh_clid,
          'offers': order_offers
        }
        var xhr = new XMLHttpRequest()
        xhr.open('POST', 'https://track.leadhit.io/stat/lead_order', true)
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8')
        xhr.onreadystatechange = function() {
          if (this.readyState != 4) return
          console.log('order sended')
        }
        xhr.send(JSON.stringify(requestBody))
      }
  },
  computed: {
    orderId() {
      return this.$store.state.checkout.orderId;
    },
    message() {
      return this.$store.state.checkout.response.message;
    },
    detail() {
      return this.$store.state.checkout.detail;
    },
    error() {
      return this.$store.state.checkout.response.error;
    },
  },
};
</script>
